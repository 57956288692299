import React from "react";
import logo from "../../assets/img/logo.png";
import facebook from "../../assets/img/facebook.png";
import insta from "../../assets/img/instagram.png";
import twitter from "../../assets/img/twitter.png";

const Footer = () => {
  return (
    <div className="p-5 footer  pb-4">
      <div className="col-md-8 col-sm-12 mx-auto py-5 border-bottom">
        <div className="row text-sm-center text-c">
          <div class="col-lg-4 col-md-6 mb-4 col-sm-6  text-lg-start">
            <div>
              <img src={logo} alt="" />
              <div className="my-4 row">
                <p className="col-12">
                  Book your trip in minute, get full Control for much longer.
                </p>
              </div>
              <div className="d-flex j-content">
                <span className="social-icons p-2 bg-white rounded-circle">
                  <i className="bi bi-facebook"></i>
                </span>
                <span className="social-icons p-2 bg-white rounded-circle mx-2">
                  <i className="bi bi-instagram "></i>
                </span>
                <span className="social-icons p-2 bg-white rounded-circle">
                  <i className="bi bi-twitter-x"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4 col-sm-6 text-lg-start">
            <h4 className="my-3">Company</h4>
            <div className="py-2">
              <span className="">About</span>
            </div>
            <div className="py-2">
              <span>Careers</span>
            </div>
          
            <div className="py-2">
              <span>Privacy & Policy</span>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mb-4 col-sm-6 text-lg-start">
            <h4 className="my-3">Contact</h4>
            <div className="py-2">
              <span>Help/FAQ</span>
            </div>
            <div className="py-2">
              <span>Press</span>
            </div>
           
          </div>
          <div class="col-lg-2 col-md-6 mb-4 col-sm-6 text-lg-start">
            <h4 className="my-3">More</h4>

            <div className="py-2">
              <span>Our Blog</span>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
