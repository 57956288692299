import React, { useEffect, useState } from "react";
import DataTables from "../../components/common/DataTables";
import AddCashPower from "../../components/forms/AddCashPower";
import { getTransactions } from "../../services/cash-power.service";
import PrintButton from "../../components/common/PrintIcon";
import moment from "moment";
import { print } from "../../helpers/printDocument";
import { filterArrayByChar } from "../../helpers/filterArrayByChar";
import { CSVLink } from 'react-csv';

const CashPower = () => {
  const [transactions, setTransactions] = useState([]);
  const [tempTransactions, setTempTransactions] = useState([]);
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  let timeout;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getCashPowerData();
  }, []);

  useEffect(() => {
    timeout = setTimeout(() => {
      filterSearch();
    }, 400);
    return () => clearTimeout(timeout);
  }, [search]);

  const filterSearch = () => {
    let records = [...tempTransactions];
    if (records.length) {
      if (search.trim()) {
        let items = filterArrayByChar(records, search);
        setTransactions(items);
      } else {
        setTransactions(records);
      }
    }
  };

  const getCashPowerData = async () => {
    setLoading(true);
    try {
      const result = await getTransactions();
      if (result.status === 200) {
        setTransactions(result?.data?.data || []);
        setTempTransactions(result?.data?.data || []);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const printRow = (row) => {
   // const token = row.token;
    const kcttoken =row.list_token;
    
    //const formattedToken = `${token.slice(0, 4)} ${token.slice(4, 8)} ${token.slice(8, 12)} ${token.slice(12, 16)} ${token.slice(16, 20)}`;


    const tokensArray = kcttoken.split(',');
    const formattedTokens = tokensArray.map(kcttoken => 
      `${kcttoken.slice(0, 4)} ${kcttoken.slice(4, 8)} ${kcttoken.slice(8, 12)} ${kcttoken.slice(12, 16)} ${kcttoken.slice(16, 20)}`
    );
    const formattedKcToken = formattedTokens.map(token => `<p style="margin: 0;"><b>${token}</b></p>`).join('');

    
    let content = `
    <style>
    p{margin:0}
    </style>
        <div style="width: 50%; margin: auto;">
        <div  style="text-align:center; display:flex;flex-direction:column;align-items:center;justify-content:center;gap:0">
             <img src="/static/media/logo.5dfcbf7049e71cdb433f.png" alt={logo} style="width: 100px; margin-bottom: 10px;" />
            </div>
            <div  style="text-align:center ; display:flex;flex-direction:column;align-items:center;justify-content:center;gap:0; margin-top:10px">
            <p style="margin:0"><b>*************************************************</b></p>
            <p style="margin-bottom:5px"><b>ELECTRICITY PAYMENT RECEIPT</b></p>
            <p style="margin:0"><b>*************************************************</b></p>
             </div>
             <div >
            <p><b>Collection Center:</b><span style="margin-left:20px"> ${row?.service_address}</span></p>
            <p><b>Payment Date:</b> <span style="margin-left:20px">${moment(row?.created_at).format('DD/MM/YY')}</span></p>
            <p><b>Payment Time:</b> <span style="margin-left:20px">${moment(row?.created_at).format('hh:mm:ss A')}</span></p>
            <p><b>Receipt Number:</b> <span style="margin-left:20px">${row?.receipt_number}</span></p>
            <p><b>Payment Method:</b> <span style="margin-left:20px">${row?.payment_method || 'N/A'}</span></p>
            <p><b>Customer:</b> <span style="margin-left:20px">${row.customer_name}</span></p>
            <p><b>Account Number:</b><span style="margin-left:20px"> ${row.account}</span></p>
            <p><b>Meter Number:</b><span style="margin-left:20px"> ${row.meter_number}</span></p>
            ${parseFloat(row.debt_payment) > 0 ? `
              <p><b>Debt Payment :</b> <span style="margin-left:20px"> ${row.debt_payment} GMD</span></p>
            ` : ''}
            ${parseFloat(row.percentage_debt) > 0 ? `
                <p><b>Percentage Debt :</b> <span style="margin-left:20px"> ${parseFloat(row.percentage_debt_calculation).toFixed(2)} % </span></p>
            ` : ''}
           
        </div>
            <div  style="text-align:center ; display:flex;flex-direction:column;align-items:center;justify-content:center;gap:0; margin-top:10px">
            <p style="margin:0">*************************************************</p>
            <p style="margin-bottom:5px">Tokens</p>
            <p style="margin:0">*************************************************</p>
           
             <p style="margin-bottom:5px"><b>   ${formattedKcToken}</b></p>
            <p style="margin:0">*************************************************</p>
            </div>
            <div >
            <p><b>Total Paid Amount:</b> <span style="margin-left:20px"> ${row.amount} GMD</span></p>
            <p><b>Fee:</b> <span style="margin-left:20px"> ${row.fee} GMD</span></p>
            <p><b>Balance:</b> <span style="margin-left:20px"> ${row.account_balance} GMD</span></p>
            <p><b>Tariff:</b> <span style="margin-left:20px">${row.tariff_description}</span></p>
            <p><b>Sale Amount:</b> <span style="margin-left:20px"> ${row.vend_amount} GMD</span></p>
            <p><b>Unit Price:</b> <span style="margin-left:20px"> ${row.priece} GMD</span></p>
            
            ${row.units_top_up && row.units_top_up.length > 0 ? `
              <div>
                ${row.units_top_up
                  .filter(item => item.concepts !== 'Rounding Adjustment') // Exclude "Rounding Adjustment"
                  .map(item => `
                    <p><b>${item.concepts === 'V.A.T.' ? `${item.concepts} (${item.price * 100}%)` : item.concepts}</b></p>
                    <p>${parseFloat(item.units).toFixed(2)} ${item.concepts === 'V.A.T.' ? '' : 'kWh'} x ${item.price}: <span style="margin-left:25px">${item.amounts} GMD</span></p>
                  `).join('')}
              </div>
            ` : ''}
            
            
        </div>
            <div  style="text-align:center ; display:flex;flex-direction:column;align-items:center;justify-content:center;gap:0; margin-top:10px">
            <p style="margin:0">*************************************************</p>
            <p style="margin-bottom:5px"><b>You were served by:</b> <br/> <span style="text-transform: capitalize;">${row.recorded_by || 'N/A'}</span></p>
            <p style="margin:0">*************************************************</p>
            <p ><b>Thank you</b></p>
         </div>
        </div>
    `;
    print(content);
  };
  const columns = [
    {
      name: "Action",
      selector: (row) => row.title,
      sortable: true,
      reorder: true,
      cell: (row) => <PrintButton row={row} print={printRow} />,
    },
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#F4F3F3",
      },
      format: (v) => {
        const dateStr = v.created_at;
        const formattedDate = moment(dateStr).format('MMM DD, YYYY, h:mm A');
        return formattedDate;
      },
    },
    {
      name: "Customer Name",
      selector: (row) => row.customer_name,
      sortable: true,
      reorder: true,
    },
    {
      name: "Meter Number",
      selector: (row) => row.meter_number,
      sortable: true,
      reorder: true,
    },
    {
      name: "Tendered Amount",
      selector: (row) => row.amount,
      sortable: true,
      reorder: true,
    },
    {
      name: "Vend Amount",
      selector: (row) => row.vend_amount,
      sortable: true,
      reorder: true,
    },
    {
      name: "Fee",
      selector: (row) => row.fee,
      sortable: true,
      reorder: true,
    },
    {
      name: "List Token",
      selector: (row) => {
        const kcttoken = row.list_token;
        
        // Split the tokens by comma
        const tokensArray = kcttoken.split(',');
    
        // Format each token (group into 4 characters separated by spaces)
        const formattedTokens = tokensArray.map(kcttoken => 
          `${kcttoken.slice(0, 4)} ${kcttoken.slice(4, 8)} ${kcttoken.slice(8, 12)} ${kcttoken.slice(12, 16)} ${kcttoken.slice(16, 20)}`
        );
    
        // Return each token wrapped in a <div> for proper display
        return (
          <div>
            {formattedTokens.map((token, index) => (
              <p key={index}>{token}</p>
            ))}
          </div>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#F4F3F3",
      }
    },

    {
      name: "Cashier",
      selector: (row) => row.recorded_by,
      sortable: true,
      reorder: true,
    },

    {
      name: "Transaction ID",
      selector: (row) => row.transaction_id,
      sortable: true,
      reorder: true,
    }
   
  ];
  // const csvData = transactions.map((credit) => ({
  //   "Date": moment(credit.created_at).format("MMM DD, YYYY"),
  //   "Customer Name": credit.customer_name,
  //   "Meter Number": credit.meter_number,
  //   "Tendered Amount": credit.amount,
  //   "Vend Amount": credit.vend_amount,
  //   "Fee": credit.fee,
  //   "DebtPayment": credit.debt_payment,
  //   "PercentageDebt": credit.percentage_debt,
  //   // "Token": credit.token,
  //   "Cashier": credit.recorded_by_user,
  //   "Transaction ID": credit.transaction_id,
  //   "List Token": credit.list_token,
  // }));
  //console.log(transactions)
  const csvData = transactions.map((transaction) => ({
    "Date": moment(transaction.created_at).format("MMM DD, YYYY"),
    "Customer Name": transaction.customer_name,
    "Meter Number": transaction.meter_number,
    "Tendered Amount": transaction.amount,
    "Vend Amount": transaction.vend_amount,
    "Fee": transaction.fee,
    "DebtPayment": transaction.debt_payment,
    "PercentageDebt": parseFloat(transaction.percentage_debt_calculation).toFixed(2),
    "Cashier": transaction.recorded_by_user,
    "Transaction ID": transaction.transaction_id,
    "List Token": transaction.list_token
  }));
  const randomFileName = `cashPower_${Math.floor(Math.random() * 100000)}.csv`;
  return (
    <div className="py-3">
      <div className="row my-4">
        <div className="col-md-12 col-sm-12 mb-2">
          <div className="p-4 d-flex justify-content-between bg-white br-20">
            <h4 className="text-indigo">New Transactions</h4>
            <span
              className="bg-indigo px-5 py-2 text-white cursor-pointer"
              onClick={handleShow}
            >
              Click Here
            </span>
          </div>
        </div>
      </div>

      <div className="br-20 bg-white p-4">
        <div className="d-flex justify-content-between">
          <h3 className="text-light-blue">All Cash Power transaction</h3>
          <div className="search-input">
            <div className="bg-white admin-search p-2 input-group br-20">
              <span className="input-group-text border-0">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="5.57617"
                    cy="5.05273"
                    r="4.3"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                  />
                  <line
                    x1="10.5862"
                    y1="11.0527"
                    x2="8.57617"
                    y2="9.04268"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
              <input
                type="text"
                className="form-control border-0 bg-light p-2"
                placeholder="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search ?
                <span onClick={() => setSearch('')} className="position-absolute fs-5 cursor-pointer z-10" style={{ top: 13, right: 60 }}>
                  <i className="bi bi-x"></i>
                </span> : ''}
              <div className="ms-2 mt-1">

                <CSVLink data={csvData} filename={randomFileName} className='text-white text-decoration-none'>
                  <svg
                    className="cursor-pointer download-csv"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.97917 13.0712L3.53625 7.62832L5.06027 6.04987L7.89058 8.88019V0.00823975H10.0677V8.88019L12.8981 6.04987L14.4221 7.62832L8.97917 13.0712ZM2.44767 17.4256C1.84895 17.4256 1.33659 17.2126 0.910594 16.7866C0.484595 16.3606 0.271234 15.8478 0.270508 15.2484V11.9826H2.44767V15.2484H15.5107V11.9826H17.6878V15.2484C17.6878 15.8471 17.4748 16.3598 17.0488 16.7866C16.6228 17.2133 16.1101 17.4263 15.5107 17.4256H2.44767Z"
                      fill="black"
                    />
                  </svg>
                </CSVLink>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DataTables data={transactions} columns={columns} loading={loading} />
        </div>
      </div>
      <AddCashPower show={show} handleClose={handleClose} refreshData={getCashPowerData} />
    </div>
  );
};
export default CashPower;
