import React, { useEffect, useState } from "react";
import DataTables from "../../components/common/DataTables";
import { getCredits } from "../../services/credits.service";
import moment from "moment";
import AddCreditForm from "../../components/forms/AddCreditForm";
import { CSVLink } from 'react-csv';

const CreditAgents = () => {
  const [credits, setCredits] = useState([]);
  const [filteredCredits, setFilteredCredits] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getCreditsData();
  }, []);

  const getCreditsData = async () => {
    try {
      const result = await getCredits();
      if (result.status === 200) {
        const creditsData = result?.data?.credits || [];
        setCredits(creditsData);
        setFilteredCredits(creditsData);
      }
    } catch (error) {
      console.error("Failed to fetch credits", error);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    filterCredits(query);
  };

  const filterCredits = (query) => {
    if (!query) {
      setFilteredCredits(credits);
    } else {
      const filteredData = credits.filter((credit) =>
        Object.values(credit).some(
          (value) => value && value.toString().toLowerCase().includes(query)
        )
      );
      setFilteredCredits(filteredData);
    }
  };


  const csvData = credits.map((credit) => ({
    "Date": moment(credit.created_at).format("MMM DD, YYYY"),
    "Agent Name": credit.agent_name,
    "Transaction Type": credit._type,
    "Amount": credit.amount,
    "Bank Transaction Reference": credit.bank_transaction_ref,
    "Created By": credit.created_by,
    "Nawec Balance": credit.nawec_balance,
  }));

  const columns = [
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#F4F3F3",
      },
      format: (v) => {
        const dateStr = v.created_at;
        const formattedDate = moment(dateStr).format("MMM DD, YYYY");
        return formattedDate;
      },
    },
    {
      name: "Agent",
      selector: (row) => row.agent_name,
      sortable: true,
      reorder: true,
    },
    {
      name: "Type",
      selector: (row) => row._type,
      sortable: true,
      reorder: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      reorder: true,
    },
    {
      name: "Bank Tran. Ref",
      selector: (row) => row.bank_transaction_ref,
      sortable: true,
      reorder: true,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by,
      sortable: true,
      reorder: true,
    },
    {
      name: "Newec Balance",
      selector: (row) => row.nawec_balance,
      sortable: true,
      reorder: true,
    },
  ];

  return (
    <div className="py-3">
      <div className="row my-4">
        <div className="col-md-12 col-sm-12 mb-2">
          <div className="p-4 d-flex justify-content-between bg-white br-20">
            <h4 className="text-indigo">Load Credit History</h4>
            <span
              className="bg-indigo px-5 py-2 text-white cursor-pointer"
              onClick={handleShow}
            >
              Click Here
            </span>
          </div>
        </div>
        
      </div>
      <div className="br-20 bg-white p-4">
        <div className="d-flex justify-content-between">
          <h3 className="text-light-blue">Credit History</h3>
          <div className="search-input">
            <div className="bg-white admin-search p-2 input-group br-20">
              <span className="input-group-text border-0">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="5.57617"
                    cy="5.05273"
                    r="4.3"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                  />
                  <line
                    x1="10.5862"
                    y1="11.0527"
                    x2="8.57617"
                    y2="9.04268"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
              <input
                type="text"
                className="form-control border-0 bg-light p-2"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <div className="ms-2 mt-1">

                <CSVLink data={csvData} filename="creditagents.csv" className='text-white text-decoration-none'>
                  <svg
                    className="cursor-pointer download-csv"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.97917 13.0712L3.53625 7.62832L5.06027 6.04987L7.89058 8.88019V0.00823975H10.0677V8.88019L12.8981 6.04987L14.4221 7.62832L8.97917 13.0712ZM2.44767 17.4256C1.84895 17.4256 1.33659 17.2126 0.910594 16.7866C0.484595 16.3606 0.271234 15.8478 0.270508 15.2484V11.9826H2.44767V15.2484H15.5107V11.9826H17.6878V15.2484C17.6878 15.8471 17.4748 16.3598 17.0488 16.7866C16.6228 17.2133 16.1101 17.4263 15.5107 17.4256H2.44767Z"
                      fill="black"
                    />
                  </svg>
                </CSVLink>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DataTables columns={columns} data={filteredCredits} />
        </div>
      </div>
      <AddCreditForm
        show={show}
        handleClose={handleClose}
        refreshCredits={getCreditsData}
      />
    </div>
  );
};

export default CreditAgents;
