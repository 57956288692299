import { API_URL, http } from "./http.service";

 

export const userBalance = () => {

    try {
        return http.get(API_URL + 'vendorInfo');
    } catch (error) {
        return error;
    }
}

export const datefilter = {
    submitdatefilter: async (data) => {
        // Create query string from data object
        const queryParams = new URLSearchParams(data).toString();

        const response = await fetch(`${API_URL}filter?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': "YIi4AxSZmWcYNjoMytH7XaUa8",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    },
};

