
import React, { useState, useEffect } from 'react';
import { userBalance } from '../../services/balance';

const Balance = () => {
    const [balanceData, setBalanceData] = useState(null);

    const getUserBalance = async () => {
        try {
            const result = await userBalance();

            if (result.status === 200) {
                setBalanceData(result?.data?.data);
               // console.log("Balance data:", result?.data);
            }
        } catch (error) {
            console.error("Failed to fetch balance", error);
        }
    };

    useEffect(() => {
        getUserBalance();
    }, []);

    return (
        <div className='pt-5'>
            <h3>Balance Details:</h3>
            {balanceData ? (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Field</th>
                            <th style={{ borderBottom: '2px solid #ddd', padding: '8px' }}>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}><strong>Balance:</strong></td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>GMD <b>{balanceData.balance}</b></td>
                        </tr>
                        <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}><strong>Vendor Description:</strong></td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{balanceData.descVendor}</td>
                        </tr>
                        <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}><strong>Vendor ID:</strong></td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{balanceData.idVendor}</td>
                        </tr>
                        <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}><strong>Modified Vendor ID:</strong></td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{balanceData.modifiedIdVendor ? "Yes" : "No"}</td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                <p className='text-center pt-5'>Loading  details...</p>
            )}
        </div>
    );
};

export default Balance;
