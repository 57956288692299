import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [showOpenMenu, setShowOpenMenu] = useState(false);
  const [showCloseMenu, setShowCloseMenu] = useState(false);

  const toggleOpenMenu = () => {
    if (!showOpenMenu) {
      document.getElementById("mySidenav").style.width = "250px";
    } else {
      document.getElementById("mySidenav").style.width = "0";
    }
    setShowOpenMenu(!showOpenMenu);
  };
  const toggleCloseMenu = () => {
    if (!showCloseMenu) {
      document.getElementById("mySidenav").style.width = "0";
    } else {
      document.getElementById("mySidenav").style.width = "250px ";
    }
    setShowCloseMenu(!showCloseMenu);
  };

  const navigate = useNavigate();
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-transparent header">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={logo} alt="" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="d-flex align-items-center">
            <div className="tab-view px-2" style={{cursor: "pointer"}}>
              <div onClick={() => navigate("/login")}>
                <FontAwesomeIcon icon={faUser} />
              </div>
            </div>
            <div
              id="mySidenav"
              className={`sidenav ${showOpenMenu ? "open" : ""}`}
            >
              <div className="container-fluid">
                <div className="nav-header d-flex align-items center justify-content-between">
                  <NavLink className="navbar-brand" to="/">
                    <img src={logo} alt="" />
                  </NavLink>
                  <a href="#!" className="closebtn" onClick={toggleCloseMenu}>
                    &times;
                  </a>
                </div>
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link className="nav-link " aria-current="page" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#!">
                      Explore
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#!">
                      Travel
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#!">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#!">
                      Pricing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <span
              style={{
                fontSize: "22px",
                cursor: "pointer",
              }}
              onClick={toggleOpenMenu}
              className="opennav"
            >
              &#9776;
            </span>
          </div>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#!">
                  Explore
                </a>
              </li>
              
              <li className="nav-item">
                <a className="nav-link" href="#!">
                  Blog
                </a>
              </li>
           
            </ul>
            <span className="navbar-text">
              <div>
                {/* <button className='btn text-white fs-5' onClick={()=>navigate('/signup')}>signup</button> */}
                <button
                  className="btn btn-orange fs-5 "
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
              </div>
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
