import { API_URL, http } from "./http.service";
// Verify the endpoint URL
export const changepassword = {
    submitchangepassword: async (data) => {
        const response = await fetch(API_URL + 'changePassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': "YIi4AxSZmWcYNjoMytH7XaUa8",
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    },
};


export const forgetpassword = {
    submitforgetpassword: async (data) => {
        const response = await fetch(API_URL + 'forgotpassowrd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': "YIi4AxSZmWcYNjoMytH7XaUa8",
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    },
};



export const updatepassword = {
    updatepassword: async (data) => {
        const response = await fetch(API_URL + 'updatepassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': "YIi4AxSZmWcYNjoMytH7XaUa8",
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    },
};





