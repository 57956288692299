export const constants = {
  routes: {
    "/admin/dashboard": "Dashboard",
    "/admin/cash-power": "Cash Power",
    "/admin/air-power": "Air Power",
    "/admin/post-paid": "Post Paid",
    "/admin/user": "User",
    "/admin/credit-agents": "Credit Agents",
    "/admin/cash-power-charges": "Cash Power Charges",
    "/admin/report": "Revenue Report",
    "/admin/backup": "Backup",
    "/admin/cleanup": "Cleanup",
    "/admin/customer-enquiries": "Customer Enquiries",
    "/admin/shift-enquiries": "Shift Enquiries",
    "/admin/change-password": "Change Password",
    "/admin/forget-password": "Nawec Forgot Password",
    "/admin/balance": "Balance"


  }
} 