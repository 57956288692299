import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTables from "../../components/common/DataTables";
import AddCashPowerCharges from "../../components/forms/AddCashPowerCharges";
import { getCashPowerCharges, deleteCashPowerChargeById } from "../../services/cash-power-charges.service";
import EditDeleteIcon from "../../components/common/EditDeleteIcon";
import { filterArrayByChar } from "../../helpers/filterArrayByChar";
import ConfirmModal from "../../components/common/ConfirmModal";

const CashPowerCharges = () => {
  const [users, setUsers] = useState([]);
  const [tempUsers, setTempUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleShow = () => {
    setShow(true);
    setSelectedUser(null);
  };

  let timeout;
  useEffect(() => {
    getCashPowerChargesData();
  }, []);

  useEffect(() => {
    timeout = setTimeout(() => {
      filterSearch();
    }, 400);
    return () => clearTimeout(timeout);
  }, [search]);



  const filterSearch = () => {
    let records = [...tempUsers];
    if (records?.length) {
      if (search?.trim()) {
        let items = filterArrayByChar(records, search);
        setUsers(items);
      } else {
        setUsers(records);
      }
    }
  };
  const getCashPowerChargesData = async () => {
    try {
      setLoading(true);
      const result = await getCashPowerCharges();
      if (result.status === 200) {
        setUsers(result?.data || []);
        setTempUsers(result?.data || []);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to fetch users.");
    }
  };

  const editRow = (row) => {
    setSelectedUser(row);
    setShow(true);
  };

  const deleteRow = (row) => {
    setSelectedUser(row);
    setDeleteShow(true);
  };

  const deleteCash = async () => {
    try {
      let index = tempUsers.findIndex((u) => u?.id === selectedUser?.id);
      let updatedUsers = [...tempUsers];
      updatedUsers.splice(index, 1);
      setUsers(updatedUsers);
      setTempUsers(updatedUsers);
      // alert(selectedUser?.id)
      let result = await deleteCashPowerChargeById(selectedUser?.id);
      if (result?.status === 200) {
        toast.success("Case Power Charges deleted successfully.");
        setSelectedUser(null);
      }
    } catch (error) {
      toast.error("Failed to delete.");
    }
    setDeleteShow(false);
  };

  const updateUserList = (user) => {
    if (selectedUser) {
      setUsers((prevUsers) =>
        prevUsers.map((u) => (u.id === user.id ? user : u))
      );
      setTempUsers((prevUsers) =>
        prevUsers.map((u) => (u.id === user.id ? user : u))
      );
    } else {
      setUsers((prevUsers) => [...prevUsers, user]);
      setTempUsers((prevUsers) => [...prevUsers, user]);
    }
  };

  const columns = [
    {
      name: "Action",
      selector: (row) => row.title,
      sortable: true,
      reorder: true,
      cell: (row) => <EditDeleteIcon row={row} deleteRow={deleteRow} edit={editRow} />,
    },
    {
      name: "Start Amount",
      selector: (row) => row.start_amount,
      sortable: true,
      reorder: true,
    },
    {
      name: "End Amount",
      selector: (row) => row.end_amount,
      sortable: true,
      reorder: true,
    },
    {
      name: "Amount",
      selector: (row) => row.commision_amount,
      sortable: true,
      reorder: true,
    },
    {
      name: "Type",
      selector: (row) => row.amount_type,
      sortable: true,
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      reorder: true,
      cell: (row) => (row.status === 1 ? "active" : "Inactive"),
    },


  ];

  return (
    <div className="py-3" id="cashpowercharges">
      <div className="row my-4">
        <div className="col-md-12 col-sm-12 mb-2">
          <div className="p-4 d-flex justify-content-between bg-white br-20">
            <h4 className="text-indigo">Add New Cash Power Charges</h4>
            <span className="bg-indigo px-5 py-2 text-white cursor-pointer" onClick={handleShow}>Click Here</span>
          </div>
        </div>
    
      </div>
      <div className="br-20 bg-white p-4">


        <DataTables
          columns={columns}
          data={users}
          pagination
          progressPending={loading}
        />
      </div>

      <AddCashPowerCharges
        show={show}
        handleClose={handleClose}
        selectedUser={selectedUser}
        getCashPowerChargesData={getCashPowerChargesData}
        updateUserList={updateUserList}
      />
      <ConfirmModal heading="Delete Case Power Charges"
        message="Are you sure want to delete?"
        show={deleteShow} onHide={handleDeleteClose} onSubmit={deleteCash} />

    </div>
  );
};

export default CashPowerCharges;
