import React from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { forgetpassword, updatepassword } from '../../services/changepasswordadmin';
import { Form, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import { getCookie } from '../../helpers/getCookie';
import { useNavigate } from "react-router-dom";

// Define validation schema
const validationSchema = Yup.object({
    idVendor: Yup.string()
    .required('Vendor Id is required'),
    codUser: Yup.string()
    .required('codUser is required'),
    
     });

     const validationSchematwo = Yup.object({
        updatepassword: Yup.string()
        .required('Password is required')
         });

const ForgetPassword = () => {
    const navigate = useNavigate();

    const handleSubmit = async (values, { resetForm }) => {

        const response = await forgetpassword.submitforgetpassword({
            idVendor: values.idVendor,
            codUser: values.codUser,
        });
        try {

            if (response.status === "error") {
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'error',
                  title: response.data,
                  showConfirmButton: false,
                  timer: 3000
                });
                
              } else if (response.status === "success") {
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'success',
                  title: 'API Password changed successfully!',
                  showConfirmButton: false,
                  timer: 3000
                });
                resetForm();                 
                
            }
          
       
              
        } catch (error) {
           // console.log(response)
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: response.data,
                showConfirmButton: false,
                timer: 3000
              });
              resetForm();
        }
    };


    const handleSubmittwo = async (values, { resetForm }) => {

        const userId = getCookie('user_id');
        const response = await updatepassword.updatepassword({
            updatepassword: values.updatepassword,
            codUserShift: userId
        });
        try {

            if (response.status === "error") {
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'error',
                  title: response.data,
                  showConfirmButton: false,
                  timer: 3000
                });
                
              } else if (response.status === "success") {
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'success',
                  title: 'API Password changed successfully!',
                  showConfirmButton: false,
                  timer: 3000
                });
                resetForm();
                localStorage.clear();
                navigate("/");

            }
          
       
              
        } catch (error) {
           // console.log(response)
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: "Failed",
                showConfirmButton: false,
                timer: 3000
              });
              resetForm();
        }
    };

    return (
        <div className='pt-5'>
            <ToastContainer />
            <div className='container'>
                <div className='w-80 px-5'>
                    <Formik
                        initialValues={{
                            idVendor: '',
                            codUser: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <FormikForm>
                                <Form.Group className="mb-3" controlId="idVendor">
                                    <Form.Label>Vendor Id</Form.Label>
                                    <Field
                                        name="idVendor"
                                        type="text"
                                        className="form-control"
                                    />
                                    <ErrorMessage name="idVendor" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="codUser">
                                    <Form.Label>Code User(username)</Form.Label>
                                    <Field
                                        name="codUser"
                                        type="text"
                                        className="form-control"
                                    />
                                    <ErrorMessage name="codUser" component="div" className="text-danger" />
                                </Form.Group>
                                <div className='d-flex  justify-content-between'>
                                    <div className=''>
                                        <Button type="submit" variant="primary">Submit</Button>

                                    </div>
                                  
                                </div>
                            </FormikForm>
                        )}
                    </Formik>


<div className="mt-5 mb-4"><h3>Update Password</h3></div>

                    <Formik
                        initialValues={{
                            updatepassword: ''
                        }}
                        validationSchema={validationSchematwo}
                        onSubmit={handleSubmittwo}
                    >
                        {() => (
                            <FormikForm>
                                <Form.Group className="mb-3" controlId="updatepassword">
                                    <Form.Label>New Password</Form.Label>
                                    <Field
                                        name="updatepassword"
                                        type="text"
                                        className="form-control"
                                    />
                                    <ErrorMessage name="updatepassword" component="div" className="text-danger" />
                                </Form.Group>

                            
                                <div className='d-flex  justify-content-between'>
                                    <div className=''>
                                        <Button type="submit" variant="primary">Update</Button>

                                    </div>
                                  
                                </div>
                            </FormikForm>
                        )}
                    </Formik>


                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
