
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { onlyNumber } from '../../helpers/onlyNumber';
import { getCookie } from '../../helpers/getCookie';
import { addNewTransaction, verifyTransaction } from '../../services/cash-power.service';
import Swal from 'sweetalert2';

const AddCashPower = ({ show, handleClose, refreshData }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userId = getCookie('user_id');
  const location = getCookie('location');
  const username = getCookie('username');

  const validationSchema = Yup.object({
    meter_number: Yup.string()
      .matches(/^\d+$/, 'Meter Number must be digits only')
      .min(8, 'Meter Number must be at least 8 digits')
      .max(50, 'Meter Number cannot exceed 50 digits')
      .required('Meter Number is required'),
      amount: Yup.number()
      .min(50, 'Amount must be at least 50')
      .typeError('Amount must be a number')
      .required('Amount is required')
      .test('max-digits', 'Amount cannot exceed 10 digits', value => {
          return value !== undefined && value.toString().length <= 10;
      }),
    telephone: Yup.string()
      .matches(/^\d+$/, 'Mobile Number must be digits only')
      .min(7, 'Mobile Number must be at least 7 digits')
      .max(15, 'Mobile Number cannot exceed 15 digits')
      .required('Mobile Number is required')
  });

  const formik = useFormik({
    initialValues: {
      meter_number: '',
      amount: '',
      telephone: '',
      user_id: userId
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (isVerified) {
        setIsSubmitting(true);
        try {
          const result = await addNewTransaction({
            ...values,
            recorded_by: username,
            user_location: location
          });

          if (result.data.status === "error") {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              title: result.data.message ||  result.data.apiError,
              showConfirmButton: false,
              timer: 3000
            });
            formik.resetForm();
            refreshData();
            handleClose();
          } else if (result.status === 200) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: 'Transaction added successfully!',
              showConfirmButton: false,
              timer: 3000
            });
            formik.resetForm();
            refreshData();
            setIsVerified(false);
            handleClose();
          }
        } catch (error) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: 'Failed to add transaction',
            showConfirmButton: false,
            timer: 3000
          });
        } finally {
          setIsSubmitting(false);
        }
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Please verify first!',
          showConfirmButton: false,
          timer: 3000
        });
      }
    }
  });

  const verifyAccount = async () => {
    if (formik.dirty && formik.isValid) {
      setIsVerifying(true);
      try {
        const result = await verifyTransaction(formik.values);
        if (result.data.status === "error") {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: result.data.message ||  result.data.apiError,
            showConfirmButton: false,
            timer: 5000
          });
        } else if (result.status === 200) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Account verified successfully!',
            showConfirmButton: false,
            timer: 5000
          });
          setIsVerified(true);
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: 'Unexpected response from server.',
            showConfirmButton: false,
            timer: 5000
          });
        }
      } catch (error) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Verification failed: ' + error.message,
          showConfirmButton: false,
          timer: 5000
        });
      } finally {
        setIsVerifying(false);
      }
    } else {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: 'Please fix validation errors',
        showConfirmButton: false,
        timer: 5000
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-indigo'>Add New Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="meterNumber">
              <Form.Label>Meter Number</Form.Label>
              <Form.Control
                name='meter_number'
                value={formik.values.meter_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                placeholder="Meter Number"
                isInvalid={!!formik.errors.meter_number && formik.touched.meter_number}
                maxLength={50}
                disabled={isVerified}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.meter_number}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                name='amount'
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                min="0"
                max={999999999}
                placeholder="Amount"
                isInvalid={!!formik.errors.amount && formik.touched.amount}
                disabled={isVerified}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.amount}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="telephone">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                name='telephone'
                value={formik.values.telephone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                onKeyPress={onlyNumber}
                maxLength={15}
                placeholder="Mobile Number"
                isInvalid={!!formik.errors.telephone && formik.touched.telephone}
                disabled={isVerified}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.telephone}
              </Form.Control.Feedback>
            </Form.Group>
            <Modal.Footer>
              <Button variant="warning" type="button" onClick={verifyAccount} disabled={isVerifying || isVerified}>
                {isVerifying ? 'Verifying...' : 'VERIFY'}
              </Button>
              <Button variant="success" type="submit" disabled={!isVerified || isSubmitting}>
                {isSubmitting ? 'Please wait...' : 'OK'}
              </Button>
              <Button variant="danger" onClick={handleClose}>
                CANCEL
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCashPower;
