import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTables from "../../components/common/DataTables";
import { getCashier } from "../../services/users.service";
import { filterArrayByChar } from "../../helpers/filterArrayByChar";
import { getUserId } from "../../helpers/getUserId";
import { ToastContainer } from 'react-toastify';
// import { CSVLink } from 'react-csv';
// import moment from "moment";

const CashierUser = () => {
    const [users, setUsers] = useState([]);
    const [tempUsers, setTempUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const user_id = getUserId();
    console.log(user_id, "0000");

    useEffect(() => {
        getUsersData();
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            filterSearch();
        }, 400);
        return () => clearTimeout(timeout);
    }, [search]);

    const filterSearch = () => {
        let records = [...tempUsers];
        if (records?.length) {
            if (search?.trim()) {
                let items = filterArrayByChar(records, search);
                setUsers(items);
            } else {
                setUsers(records);
            }
        }
    };

    const getUsersData = async () => {
        setLoading(true);
        try {
            const result = await getCashier(user_id);
            if (result.status === 200) {
                const data = Array.isArray(result.data) ? result.data : [result.data];
                setUsers(data);
                setTempUsers(data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error("Failed to fetch users:", error);
            toast.error("Failed to fetch users");
        }
    };

    const columns = [
        {
            name: "Name",
            selector: (row) => row?.data?.name,
            sortable: true,
            reorder: true,
        },
        {
            name: "User Name",
            selector: (row) => row?.data?.email,
            sortable: true,
            reorder: true,
        },
        {
            name: "Address",
            selector: (row) => row?.data?.address,
            sortable: true,
            reorder: true,
        },
        {
            name: "Location",
            selector: (row) => row?.data?.location,
            sortable: true,
            reorder: true,
        },
        {
            name: "User Type",
            selector: (row) => row?.data?.user_type,
            sortable: true,
            reorder: true,
        },
        {
            name: "Cash Power Balance",
            selector: (row) => row?.data?.nawec_balance,
            sortable: true,
            reorder: true,
        },
    ];

    // const csvData = users.map(user => ({
    //     "Date": moment(user?.data?.created_at).format("MMM DD, YYYY"),
    //     "Name": user?.data?.name,
    //     "User Name": user?.data?.email,
    //     "Address": user?.data?.address,
    //     "Location": user?.data?.location,
    //     "User Type": user?.data?.user_type,
    //     "Cash Power Balance": user?.data?.nawec_balance,
    // }));

    return (
        <div className="py-3">
            <ToastContainer />
            <div className="br-20 bg-white p-4">
                <div className="d-flex justify-content-between">
                    <h3 className="text-light-blue">
                        User Detail
                    </h3>
                    {/*    <div className="search-input">
                         <div className="bg-white admin-search p-2 input-group br-20">
                           <div className="ms-2 mt-1">
                                <CSVLink
                                    data={csvData}
                                    filename="users.csv"
                                    className="btn btn-link"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <svg
                                        className="cursor-pointer"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.97917 13.0712L3.53625 7.62832L5.06027 6.04987L7.89058 8.88019V0.00823975H10.0677V8.88019L12.8981 6.04987L14.4221 7.62832L8.97917 13.0712ZM2.44767 17.4256C1.84895 17.4256 1.33659 17.2126 0.910594 16.7866C0.484595 16.3606 0.271234 15.8478 0.270508 15.2484V11.9826H2.44767V15.2484H15.5107V11.9826H17.6878V15.2484C17.6878 15.8471 17.4748 16.3598 17.0488 16.7866C16.6228 17.2133 16.1101 17.4256 15.5107 17.4256H2.44767Z"
                                            fill="#23AEE6"
                                        />
                                    </svg>
                                </CSVLink>
                            </div> 
                        </div>
                    </div>*/}
                </div>
                <DataTables
                    columns={columns}
                    data={users}
                    pagination
                    progressPending={loading}
                    noDataComponent="No users found"
                />
            </div>
        </div>
    );
};
export default CashierUser;