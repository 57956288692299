import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { addUser, updateUserById } from "../../services/users.service";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
const AddUserForm = ({ show, handleClose, selectedUser, getUsersData }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    password: "",
    address: "",
    location: "",
    user_type: "Admin",
    contract_type: "Commission",
    commission: "",
    can_access_post_paid: false,
  });

  useEffect(() => {
    if (selectedUser) {
      setInitialValues({
        ...selectedUser,
        can_access_post_paid: selectedUser.can_access_post_paid === "1",
        commission: selectedUser.commission || "",
        contract_type: selectedUser.contract_type || "Commission",
      });
    } else {
      setInitialValues({
        name: "",
        email: "",
        password: "",
        address: "",
        location: "",
        user_type: "Admin",
        contract_type: "Commission",
        commission: "",
        can_access_post_paid: false,
      });
    }
  }, [selectedUser]);

  const createValidationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string().required("Password is required"),
    address: Yup.string().required("Address is required"),
    location: Yup.string().required("Location is required"),
    commission: Yup.number().min(0, "Commission must be a positive number").notRequired(),
  });

  const updateValidationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string().notRequired(),
    address: Yup.string().required("Address is required"),
    location: Yup.string().required("Location is required"),
    commission: Yup.number().min(0, "Commission must be a positive number").notRequired(),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const userData = {
        ...values,
        commission: values.commission === "" ? "0" : values.commission,
        can_access_post_paid: values.can_access_post_paid ? "1" : "0",
      };

      let result;
      if (selectedUser?.id) {
        result = await updateUserById(selectedUser.id, userData);
      } else {
        result = await addUser(userData);
      }
      if (result.status === 422) {
        const errorMessages = result.data.errors;
        if (errorMessages) {
          Object.values(errorMessages).flat().forEach(message => {
            toast.error(message);
          });
        } else {
          toast.error(result.data.message || "An error occurred. Please try again.");
        }
      } else if (result.data && result.data.status === "Error") {
        toast.error(result.data.message || "An error occurred. Please try again.");
      } else if (result.status === 200) {
        const successMessage = selectedUser ? "User updated successfully" : "User created successfully";
        toast.success(successMessage);
        getUsersData();
        handleClose();
        resetForm();
      } else {
        toast.error(result.data.message || "An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Submit Error:", error.response.data.message);
      toast.error(error.response.data.message);
    }
    setSubmitting(false);
  };

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-indigo">
            {selectedUser ? "Update" : "Add"} User
          </Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={selectedUser ? updateValidationSchema : createValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Name"
                        isInvalid={touched.name && errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Username/Email</Form.Label>
                      <Form.Control
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="email"
                        placeholder="Username/Email"
                        isInvalid={touched.email && errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="password"
                        placeholder="Password"
                        isInvalid={touched.password && errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="address">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Address"
                        isInvalid={touched.address && errors.address}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="location">
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        name="location"
                        value={values.location}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Location"
                        isInvalid={touched.location && errors.location}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.location}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="user_type">
                      <Form.Label>User Type</Form.Label>
                      <Form.Select
                        className="mb-3"
                        value={values.user_type}
                        name="user_type"
                        onChange={handleChange}
                        aria-label="User Type"
                      >
                        <option value="Admin">Admin</option>
                        <option value="Cashier">Cashier</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="contract_type">
                      <Form.Label>Contract Type</Form.Label>
                      <Form.Select
                        className="mb-3"
                        value={values.contract_type}
                        name="contract_type"
                        onChange={handleChange}
                        aria-label="Contract Type"
                      >
                        <option value="Commission">Commission</option>
                        <option value="Salary">Salary</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="commission">
                      <Form.Label>Commission</Form.Label>
                      <Form.Control
                        name="commission"
                        value={values.commission}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        placeholder="Commission"
                        min="0"
                        onKeyPress={(e) => {
                          if (e.key === '-' || e.key === '+') {
                            e.preventDefault();
                          }
                        }}
                        isInvalid={touched.commission && errors.commission}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.commission}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {selectedUser ? "Update" : "Add"} User
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUserForm;
