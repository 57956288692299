import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { getCashiers } from "../../services/reports.service";
import { shfitEnquire } from '../../services/shiftEnquiries';
import DataTables from '../../components/common/DataTables';
import { CSVLink } from 'react-csv';
import moment from "moment";

const validationSchema = Yup.object({
    paymentDate: Yup.date().required('Payment date is required'),
    codUserShift: Yup.string().required('User is required'),
});

const ShiftEnquiries = () => {
    const [shiftEnquiries, setShiftEnquiries] = useState([]);
    const [enquiries, setEnquiries] = useState([]);
    const [users, setUsers] = useState([]);
    const ref = useRef(null);

    useEffect(() => {
        getRevenueReportData();
    }, []);

    const getRevenueReportData = async () => {
        try {
            const result = await getCashiers();
            if (result.status === 200) {
                setUsers(result.data?.cashiers || []);
            }
        } catch (error) {
            console.error("Error fetching cashiers:", error);
        }
    };

    const handleSubmit = async (values, { resetForm }) => {
        const formattedValues = {
            ...values,
            paymentDate: dayjs(values.paymentDate).format('YYYY-MM-DD'),
        };

        try {
            console.log('Submitting values:', formattedValues);

            const response = await shfitEnquire.submitEnquiry(formattedValues);
            setShiftEnquiries(response.data.map(item => ({
                ...item,
                dateTransaction: new Date(item.dateTransaction).toLocaleDateString(),
            })));
            console.log('API response:', response);
            setEnquiries(response.data.map(item => ({
                ...item,
                dateTransaction: new Date(item.dateTransaction).toLocaleDateString(),
            })));

            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Data submitted successfully!',
                showConfirmButton: false,
                timer: 3000,
            });

            resetForm();
        } catch (error) {
            console.error('Submit error:', error);

            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Failed to submit data. Please try again.',
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    const columns = [
        {
            name: "Account",
            selector: (row) => row.account,
            sortable: true,
            reorder: true,
        },

        {
            name: "Meter Serial",
            selector: (row) => row.meter_number,
            sortable: true,
            reorder: true,
        },
        {
            name: "Customer Name",
            selector: (row) => row.customer_name,
            sortable: true,
            reorder: true,
        },


        {
            name: "Total Amount",
            selector: (row) => row.vend_amount,
            sortable: true,
            reorder: true,
        },
        {
            name: "Unit",
            selector: (row) => row.unit_watt,
            sortable: true,
            reorder: true,
        },
        {
            name: "Receipt",
            selector: (row) => row.receipt_number,
            sortable: true,
            reorder: true,
        },

        {
            name: "Transaction Date",
            selector: (row) => row.created_at,
            sortable: true,
            reorder: true,
            style: {
                backgroundColor: "#F4F3F3",
            },
            format: (v) => {
                const dateStr = v.created_at; // Access the correct property
                const formattedDate = moment(dateStr).format('MMM DD, YYYY, h:mm A');
                return formattedDate;
            },
        },

        {
            name: "Cashier",
            selector: (row) => row.recorded_by_user,
            sortable: true,
            reorder: true
           
        },

    ];


    const csvData = enquiries.map((item) => ({
        "Transaction ID": item.transaction_id,
        "Meter Serial": item.meter_number,
        "Customer Name": item.customer_name,
        "Date Transaction": item.payment_date,
        "Total Amount": item.amount,
        "Unit": item.unit_watt,
        "Unit Payment": item.vend_amount,
        "Receipt": item.receipt_number,
        "Account": item.account,
        "Cashier": item.recorded_by_user,
    }));

    return (
        <div className='pt-5'>
            <div className='container'>
                <div className='w-80 px-5'>
                    <Formik
                        initialValues={{
                            paymentDate: '',
                            codUserShift: '', // Add the entity field
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, setFieldValue, handleBlur, values }) => (
                            <FormikForm>
                                <Form.Group className="mb-3" controlId="paymentDate">
                                    <Form.Label>Payment Date</Form.Label>
                                    <Field
                                        name="paymentDate"
                                        type="date" // Correct field type
                                        className="form-control"
                                    />
                                    <ErrorMessage name="paymentDate" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="codUserShift">
                                    <Form.Label>Users</Form.Label>
                                    <Field
                                        as="select"
                                        name="codUserShift"
                                        className="form-select custom-select"
                                        value={values.codUserShift}
                                        onChange={(e) => setFieldValue('codUserShift', e.target.value)}
                                        onBlur={handleBlur}
                                    >
                                        <option value="">Select a User</option>
                                        {users?.map((user) => (
                                            <option key={user?.id} value={user?.id}>
                                                {user?.name}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="codUserShift" component="div" className="text-danger" />
                                </Form.Group>

                                <div className=''>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Waiting...' : 'Submit'}
                                    </Button>
                                </div>
                            </FormikForm>
                        )}
                    </Formik>
                </div>

                <div className='p-0 m-0' >
                    {/* <Button className='mb-4' variant="primary" style={{ float: "right" }}>
                        <CSVLink data={csvData} filename="shiftenquiries.csv" className='text-white text-decoration-none'>
                            Download CSV
                        </CSVLink>
                    </Button> */}


                    {enquiries.length > 0 && (
                        <Button className='mb-4' variant="primary" style={{ float: "right" }}>
                            <CSVLink data={csvData} filename="shiftenquiries.csv" className='text-white text-decoration-none'>
                                Download CSV
                            </CSVLink>
                        </Button>
                    )}


                    <DataTables columns={columns} data={shiftEnquiries} />
                </div>
            </div>
        </div>
    );
};

export default ShiftEnquiries;
